$(function(){
   let show = false;
   $('#header_user_email').off('click').on('click', function(){
      show = !show
      if(show){
         $('#header_user_email .dropdown_menu').removeClass('disabled'); 
      }else{
         $('#header_user_email .dropdown_menu').addClass('disabled');
      }
      
   });
});


// toggle menu for responsive


 var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};
    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
       console.log("----------------- hambarger");
      forEach(hamburgers, function(hamburger) {
       hamburger.addEventListener("click", function() {
          this.classList.toggle("is-active");
       }, false);
      });
}





 $("#nav-toggle").click(function () {
   $("#gloval-nav").fadeToggle(1500);
   $("#slide").fadeToggle(1500);
   $('#stork-mean').fadeToggle(1500);
   $("#button").fadeToggle(1500);
 });